import * as _caniuseLite2 from "caniuse-lite";

var _caniuseLite = "default" in _caniuseLite2 ? _caniuseLite2.default : _caniuseLite2;

import * as _borderRadius2 from "caniuse-lite/data/features/border-radius";

var _borderRadius = "default" in _borderRadius2 ? _borderRadius2.default : _borderRadius2;

import * as _cssBoxshadow2 from "caniuse-lite/data/features/css-boxshadow";

var _cssBoxshadow = "default" in _cssBoxshadow2 ? _cssBoxshadow2.default : _cssBoxshadow2;

import * as _cssAnimation2 from "caniuse-lite/data/features/css-animation";

var _cssAnimation = "default" in _cssAnimation2 ? _cssAnimation2.default : _cssAnimation2;

import * as _cssTransitions2 from "caniuse-lite/data/features/css-transitions";

var _cssTransitions = "default" in _cssTransitions2 ? _cssTransitions2.default : _cssTransitions2;

import * as _transforms2d2 from "caniuse-lite/data/features/transforms2d";

var _transforms2d = "default" in _transforms2d2 ? _transforms2d2.default : _transforms2d2;

import * as _transforms3d2 from "caniuse-lite/data/features/transforms3d";

var _transforms3d = "default" in _transforms3d2 ? _transforms3d2.default : _transforms3d2;

import * as _cssGradients2 from "caniuse-lite/data/features/css-gradients";

var _cssGradients = "default" in _cssGradients2 ? _cssGradients2.default : _cssGradients2;

import * as _css3Boxsizing2 from "caniuse-lite/data/features/css3-boxsizing";

var _css3Boxsizing = "default" in _css3Boxsizing2 ? _css3Boxsizing2.default : _css3Boxsizing2;

import * as _cssFilters2 from "caniuse-lite/data/features/css-filters";

var _cssFilters = "default" in _cssFilters2 ? _cssFilters2.default : _cssFilters2;

import * as _cssFilterFunction2 from "caniuse-lite/data/features/css-filter-function";

var _cssFilterFunction = "default" in _cssFilterFunction2 ? _cssFilterFunction2.default : _cssFilterFunction2;

import * as _cssBackdropFilter2 from "caniuse-lite/data/features/css-backdrop-filter";

var _cssBackdropFilter = "default" in _cssBackdropFilter2 ? _cssBackdropFilter2.default : _cssBackdropFilter2;

import * as _cssElementFunction2 from "caniuse-lite/data/features/css-element-function";

var _cssElementFunction = "default" in _cssElementFunction2 ? _cssElementFunction2.default : _cssElementFunction2;

import * as _multicolumn2 from "caniuse-lite/data/features/multicolumn";

var _multicolumn = "default" in _multicolumn2 ? _multicolumn2.default : _multicolumn2;

import * as _userSelectNone2 from "caniuse-lite/data/features/user-select-none";

var _userSelectNone = "default" in _userSelectNone2 ? _userSelectNone2.default : _userSelectNone2;

import * as _flexbox2 from "caniuse-lite/data/features/flexbox";

var _flexbox = "default" in _flexbox2 ? _flexbox2.default : _flexbox2;

import * as _calc2 from "caniuse-lite/data/features/calc";

var _calc = "default" in _calc2 ? _calc2.default : _calc2;

import * as _backgroundImgOpts2 from "caniuse-lite/data/features/background-img-opts";

var _backgroundImgOpts = "default" in _backgroundImgOpts2 ? _backgroundImgOpts2.default : _backgroundImgOpts2;

import * as _backgroundClipText2 from "caniuse-lite/data/features/background-clip-text";

var _backgroundClipText = "default" in _backgroundClipText2 ? _backgroundClipText2.default : _backgroundClipText2;

import * as _fontFeature2 from "caniuse-lite/data/features/font-feature";

var _fontFeature = "default" in _fontFeature2 ? _fontFeature2.default : _fontFeature2;

import * as _fontKerning2 from "caniuse-lite/data/features/font-kerning";

var _fontKerning = "default" in _fontKerning2 ? _fontKerning2.default : _fontKerning2;

import * as _borderImage2 from "caniuse-lite/data/features/border-image";

var _borderImage = "default" in _borderImage2 ? _borderImage2.default : _borderImage2;

import * as _cssSelection2 from "caniuse-lite/data/features/css-selection";

var _cssSelection = "default" in _cssSelection2 ? _cssSelection2.default : _cssSelection2;

import * as _cssPlaceholder2 from "caniuse-lite/data/features/css-placeholder";

var _cssPlaceholder = "default" in _cssPlaceholder2 ? _cssPlaceholder2.default : _cssPlaceholder2;

import * as _cssPlaceholderShown2 from "caniuse-lite/data/features/css-placeholder-shown";

var _cssPlaceholderShown = "default" in _cssPlaceholderShown2 ? _cssPlaceholderShown2.default : _cssPlaceholderShown2;

import * as _cssHyphens2 from "caniuse-lite/data/features/css-hyphens";

var _cssHyphens = "default" in _cssHyphens2 ? _cssHyphens2.default : _cssHyphens2;

import * as _fullscreen2 from "caniuse-lite/data/features/fullscreen";

var _fullscreen = "default" in _fullscreen2 ? _fullscreen2.default : _fullscreen2;

import * as _cssFileSelectorButton2 from "caniuse-lite/data/features/css-file-selector-button";

var _cssFileSelectorButton = "default" in _cssFileSelectorButton2 ? _cssFileSelectorButton2.default : _cssFileSelectorButton2;

import * as _cssAutofill2 from "caniuse-lite/data/features/css-autofill";

var _cssAutofill = "default" in _cssAutofill2 ? _cssAutofill2.default : _cssAutofill2;

import * as _css3Tabsize2 from "caniuse-lite/data/features/css3-tabsize";

var _css3Tabsize = "default" in _css3Tabsize2 ? _css3Tabsize2.default : _css3Tabsize2;

import * as _intrinsicWidth2 from "caniuse-lite/data/features/intrinsic-width";

var _intrinsicWidth = "default" in _intrinsicWidth2 ? _intrinsicWidth2.default : _intrinsicWidth2;

import * as _cssWidthStretch2 from "caniuse-lite/data/features/css-width-stretch";

var _cssWidthStretch = "default" in _cssWidthStretch2 ? _cssWidthStretch2.default : _cssWidthStretch2;

import * as _css3CursorsNewer2 from "caniuse-lite/data/features/css3-cursors-newer";

var _css3CursorsNewer = "default" in _css3CursorsNewer2 ? _css3CursorsNewer2.default : _css3CursorsNewer2;

import * as _css3CursorsGrab2 from "caniuse-lite/data/features/css3-cursors-grab";

var _css3CursorsGrab = "default" in _css3CursorsGrab2 ? _css3CursorsGrab2.default : _css3CursorsGrab2;

import * as _cssSticky2 from "caniuse-lite/data/features/css-sticky";

var _cssSticky = "default" in _cssSticky2 ? _cssSticky2.default : _cssSticky2;

import * as _pointer2 from "caniuse-lite/data/features/pointer";

var _pointer = "default" in _pointer2 ? _pointer2.default : _pointer2;

import * as _textDecoration2 from "caniuse-lite/data/features/text-decoration";

var _textDecoration = "default" in _textDecoration2 ? _textDecoration2.default : _textDecoration2;

import * as _mdnTextDecorationShorthand2 from "caniuse-lite/data/features/mdn-text-decoration-shorthand";

var _mdnTextDecorationShorthand = "default" in _mdnTextDecorationShorthand2 ? _mdnTextDecorationShorthand2.default : _mdnTextDecorationShorthand2;

import * as _mdnTextDecorationColor2 from "caniuse-lite/data/features/mdn-text-decoration-color";

var _mdnTextDecorationColor = "default" in _mdnTextDecorationColor2 ? _mdnTextDecorationColor2.default : _mdnTextDecorationColor2;

import * as _mdnTextDecorationLine2 from "caniuse-lite/data/features/mdn-text-decoration-line";

var _mdnTextDecorationLine = "default" in _mdnTextDecorationLine2 ? _mdnTextDecorationLine2.default : _mdnTextDecorationLine2;

import * as _mdnTextDecorationStyle2 from "caniuse-lite/data/features/mdn-text-decoration-style";

var _mdnTextDecorationStyle = "default" in _mdnTextDecorationStyle2 ? _mdnTextDecorationStyle2.default : _mdnTextDecorationStyle2;

import * as _textSizeAdjust2 from "caniuse-lite/data/features/text-size-adjust";

var _textSizeAdjust = "default" in _textSizeAdjust2 ? _textSizeAdjust2.default : _textSizeAdjust2;

import * as _cssMasks2 from "caniuse-lite/data/features/css-masks";

var _cssMasks = "default" in _cssMasks2 ? _cssMasks2.default : _cssMasks2;

import * as _cssClipPath2 from "caniuse-lite/data/features/css-clip-path";

var _cssClipPath = "default" in _cssClipPath2 ? _cssClipPath2.default : _cssClipPath2;

import * as _cssBoxdecorationbreak2 from "caniuse-lite/data/features/css-boxdecorationbreak";

var _cssBoxdecorationbreak = "default" in _cssBoxdecorationbreak2 ? _cssBoxdecorationbreak2.default : _cssBoxdecorationbreak2;

import * as _objectFit2 from "caniuse-lite/data/features/object-fit";

var _objectFit = "default" in _objectFit2 ? _objectFit2.default : _objectFit2;

import * as _cssShapes2 from "caniuse-lite/data/features/css-shapes";

var _cssShapes = "default" in _cssShapes2 ? _cssShapes2.default : _cssShapes2;

import * as _textOverflow2 from "caniuse-lite/data/features/text-overflow";

var _textOverflow = "default" in _textOverflow2 ? _textOverflow2.default : _textOverflow2;

import * as _cssDeviceadaptation2 from "caniuse-lite/data/features/css-deviceadaptation";

var _cssDeviceadaptation = "default" in _cssDeviceadaptation2 ? _cssDeviceadaptation2.default : _cssDeviceadaptation2;

import * as _cssMediaResolution2 from "caniuse-lite/data/features/css-media-resolution";

var _cssMediaResolution = "default" in _cssMediaResolution2 ? _cssMediaResolution2.default : _cssMediaResolution2;

import * as _cssTextAlignLast2 from "caniuse-lite/data/features/css-text-align-last";

var _cssTextAlignLast = "default" in _cssTextAlignLast2 ? _cssTextAlignLast2.default : _cssTextAlignLast2;

import * as _cssCrispEdges2 from "caniuse-lite/data/features/css-crisp-edges";

var _cssCrispEdges = "default" in _cssCrispEdges2 ? _cssCrispEdges2.default : _cssCrispEdges2;

import * as _cssLogicalProps2 from "caniuse-lite/data/features/css-logical-props";

var _cssLogicalProps = "default" in _cssLogicalProps2 ? _cssLogicalProps2.default : _cssLogicalProps2;

import * as _cssAppearance2 from "caniuse-lite/data/features/css-appearance";

var _cssAppearance = "default" in _cssAppearance2 ? _cssAppearance2.default : _cssAppearance2;

import * as _cssSnappoints2 from "caniuse-lite/data/features/css-snappoints";

var _cssSnappoints = "default" in _cssSnappoints2 ? _cssSnappoints2.default : _cssSnappoints2;

import * as _cssRegions2 from "caniuse-lite/data/features/css-regions";

var _cssRegions = "default" in _cssRegions2 ? _cssRegions2.default : _cssRegions2;

import * as _cssImageSet2 from "caniuse-lite/data/features/css-image-set";

var _cssImageSet = "default" in _cssImageSet2 ? _cssImageSet2.default : _cssImageSet2;

import * as _cssWritingMode2 from "caniuse-lite/data/features/css-writing-mode";

var _cssWritingMode = "default" in _cssWritingMode2 ? _cssWritingMode2.default : _cssWritingMode2;

import * as _cssCrossFade2 from "caniuse-lite/data/features/css-cross-fade";

var _cssCrossFade = "default" in _cssCrossFade2 ? _cssCrossFade2.default : _cssCrossFade2;

import * as _cssReadOnlyWrite2 from "caniuse-lite/data/features/css-read-only-write";

var _cssReadOnlyWrite = "default" in _cssReadOnlyWrite2 ? _cssReadOnlyWrite2.default : _cssReadOnlyWrite2;

import * as _textEmphasis2 from "caniuse-lite/data/features/text-emphasis";

var _textEmphasis = "default" in _textEmphasis2 ? _textEmphasis2.default : _textEmphasis2;

import * as _cssGrid2 from "caniuse-lite/data/features/css-grid";

var _cssGrid = "default" in _cssGrid2 ? _cssGrid2.default : _cssGrid2;

import * as _cssTextSpacing2 from "caniuse-lite/data/features/css-text-spacing";

var _cssTextSpacing = "default" in _cssTextSpacing2 ? _cssTextSpacing2.default : _cssTextSpacing2;

import * as _cssAnyLink2 from "caniuse-lite/data/features/css-any-link";

var _cssAnyLink = "default" in _cssAnyLink2 ? _cssAnyLink2.default : _cssAnyLink2;

import * as _mdnCssUnicodeBidiIsolate2 from "caniuse-lite/data/features/mdn-css-unicode-bidi-isolate";

var _mdnCssUnicodeBidiIsolate = "default" in _mdnCssUnicodeBidiIsolate2 ? _mdnCssUnicodeBidiIsolate2.default : _mdnCssUnicodeBidiIsolate2;

import * as _mdnCssUnicodeBidiPlaintext2 from "caniuse-lite/data/features/mdn-css-unicode-bidi-plaintext";

var _mdnCssUnicodeBidiPlaintext = "default" in _mdnCssUnicodeBidiPlaintext2 ? _mdnCssUnicodeBidiPlaintext2.default : _mdnCssUnicodeBidiPlaintext2;

import * as _mdnCssUnicodeBidiIsolateOverride2 from "caniuse-lite/data/features/mdn-css-unicode-bidi-isolate-override";

var _mdnCssUnicodeBidiIsolateOverride = "default" in _mdnCssUnicodeBidiIsolateOverride2 ? _mdnCssUnicodeBidiIsolateOverride2.default : _mdnCssUnicodeBidiIsolateOverride2;

import * as _cssOverscrollBehavior2 from "caniuse-lite/data/features/css-overscroll-behavior";

var _cssOverscrollBehavior = "default" in _cssOverscrollBehavior2 ? _cssOverscrollBehavior2.default : _cssOverscrollBehavior2;

import * as _cssTextOrientation2 from "caniuse-lite/data/features/css-text-orientation";

var _cssTextOrientation = "default" in _cssTextOrientation2 ? _cssTextOrientation2.default : _cssTextOrientation2;

import * as _cssPrintColorAdjust2 from "caniuse-lite/data/features/css-print-color-adjust";

var _cssPrintColorAdjust = "default" in _cssPrintColorAdjust2 ? _cssPrintColorAdjust2.default : _cssPrintColorAdjust2;

var exports = {};
let unpack = _caniuseLite.feature;

function browsersSort(a, b) {
  a = a.split(" ");
  b = b.split(" ");

  if (a[0] > b[0]) {
    return 1;
  } else if (a[0] < b[0]) {
    return -1;
  } else {
    return Math.sign(parseFloat(a[1]) - parseFloat(b[1]));
  }
} // Convert Can I Use data


function f(data, opts, callback) {
  data = unpack(data);

  if (!callback) {
    ;
    [callback, opts] = [opts, {}];
  }

  let match = opts.match || /\sx($|\s)/;
  let need = [];

  for (let browser in data.stats) {
    let versions = data.stats[browser];

    for (let version in versions) {
      let support = versions[version];

      if (support.match(match)) {
        need.push(browser + " " + version);
      }
    }
  }

  callback(need.sort(browsersSort));
} // Add data for all properties


let result = {};

function prefix(names, data) {
  for (let name of names) {
    result[name] = Object.assign({}, data);
  }
}

function add(names, data) {
  for (let name of names) {
    result[name].browsers = result[name].browsers.concat(data.browsers).sort(browsersSort);
  }
}

exports = result; // Border Radius

let prefixBorderRadius = _borderRadius;
f(prefixBorderRadius, browsers => prefix(["border-radius", "border-top-left-radius", "border-top-right-radius", "border-bottom-right-radius", "border-bottom-left-radius"], {
  mistakes: ["-khtml-", "-ms-", "-o-"],
  feature: "border-radius",
  browsers
})); // Box Shadow

let prefixBoxshadow = _cssBoxshadow;
f(prefixBoxshadow, browsers => prefix(["box-shadow"], {
  mistakes: ["-khtml-"],
  feature: "css-boxshadow",
  browsers
})); // Animation

let prefixAnimation = _cssAnimation;
f(prefixAnimation, browsers => prefix(["animation", "animation-name", "animation-duration", "animation-delay", "animation-direction", "animation-fill-mode", "animation-iteration-count", "animation-play-state", "animation-timing-function", "@keyframes"], {
  mistakes: ["-khtml-", "-ms-"],
  feature: "css-animation",
  browsers
})); // Transition

let prefixTransition = _cssTransitions;
f(prefixTransition, browsers => prefix(["transition", "transition-property", "transition-duration", "transition-delay", "transition-timing-function"], {
  mistakes: ["-khtml-", "-ms-"],
  browsers,
  feature: "css-transitions"
})); // Transform 2D

let prefixTransform2d = _transforms2d;
f(prefixTransform2d, browsers => prefix(["transform", "transform-origin"], {
  feature: "transforms2d",
  browsers
})); // Transform 3D

let prefixTransforms3d = _transforms3d;
f(prefixTransforms3d, browsers => {
  prefix(["perspective", "perspective-origin"], {
    feature: "transforms3d",
    browsers
  });
  return prefix(["transform-style"], {
    mistakes: ["-ms-", "-o-"],
    browsers,
    feature: "transforms3d"
  });
});
f(prefixTransforms3d, {
  match: /y\sx|y\s#2/
}, browsers => prefix(["backface-visibility"], {
  mistakes: ["-ms-", "-o-"],
  feature: "transforms3d",
  browsers
})); // Gradients

let prefixGradients = _cssGradients;
f(prefixGradients, {
  match: /y\sx/
}, browsers => prefix(["linear-gradient", "repeating-linear-gradient", "radial-gradient", "repeating-radial-gradient"], {
  props: ["background", "background-image", "border-image", "mask", "list-style", "list-style-image", "content", "mask-image"],
  mistakes: ["-ms-"],
  feature: "css-gradients",
  browsers
}));
f(prefixGradients, {
  match: /a\sx/
}, browsers => {
  browsers = browsers.map(i => {
    if (/firefox|op/.test(i)) {
      return i;
    } else {
      return `${i} old`;
    }
  });
  return add(["linear-gradient", "repeating-linear-gradient", "radial-gradient", "repeating-radial-gradient"], {
    feature: "css-gradients",
    browsers
  });
}); // Box sizing

let prefixBoxsizing = _css3Boxsizing;
f(prefixBoxsizing, browsers => prefix(["box-sizing"], {
  feature: "css3-boxsizing",
  browsers
})); // Filter Effects

let prefixFilters = _cssFilters;
f(prefixFilters, browsers => prefix(["filter"], {
  feature: "css-filters",
  browsers
})); // filter() function

let prefixFilterFunction = _cssFilterFunction;
f(prefixFilterFunction, browsers => prefix(["filter-function"], {
  props: ["background", "background-image", "border-image", "mask", "list-style", "list-style-image", "content", "mask-image"],
  feature: "css-filter-function",
  browsers
})); // Backdrop-filter

let prefixBackdrop = _cssBackdropFilter;
f(prefixBackdrop, {
  match: /y\sx|y\s#2/
}, browsers => prefix(["backdrop-filter"], {
  feature: "css-backdrop-filter",
  browsers
})); // element() function

let prefixElementFunction = _cssElementFunction;
f(prefixElementFunction, browsers => prefix(["element"], {
  props: ["background", "background-image", "border-image", "mask", "list-style", "list-style-image", "content", "mask-image"],
  feature: "css-element-function",
  browsers
})); // Multicolumns

let prefixMulticolumns = _multicolumn;
f(prefixMulticolumns, browsers => {
  prefix(["columns", "column-width", "column-gap", "column-rule", "column-rule-color", "column-rule-width", "column-count", "column-rule-style", "column-span", "column-fill"], {
    feature: "multicolumn",
    browsers
  });
  let noff = browsers.filter(i => !/firefox/.test(i));
  prefix(["break-before", "break-after", "break-inside"], {
    feature: "multicolumn",
    browsers: noff
  });
}); // User select

let prefixUserSelect = _userSelectNone;
f(prefixUserSelect, browsers => prefix(["user-select"], {
  mistakes: ["-khtml-"],
  feature: "user-select-none",
  browsers
})); // Flexible Box Layout

let prefixFlexbox = _flexbox;
f(prefixFlexbox, {
  match: /a\sx/
}, browsers => {
  browsers = browsers.map(i => {
    if (/ie|firefox/.test(i)) {
      return i;
    } else {
      return `${i} 2009`;
    }
  });
  prefix(["display-flex", "inline-flex"], {
    props: ["display"],
    feature: "flexbox",
    browsers
  });
  prefix(["flex", "flex-grow", "flex-shrink", "flex-basis"], {
    feature: "flexbox",
    browsers
  });
  prefix(["flex-direction", "flex-wrap", "flex-flow", "justify-content", "order", "align-items", "align-self", "align-content"], {
    feature: "flexbox",
    browsers
  });
});
f(prefixFlexbox, {
  match: /y\sx/
}, browsers => {
  add(["display-flex", "inline-flex"], {
    feature: "flexbox",
    browsers
  });
  add(["flex", "flex-grow", "flex-shrink", "flex-basis"], {
    feature: "flexbox",
    browsers
  });
  add(["flex-direction", "flex-wrap", "flex-flow", "justify-content", "order", "align-items", "align-self", "align-content"], {
    feature: "flexbox",
    browsers
  });
}); // calc() unit

let prefixCalc = _calc;
f(prefixCalc, browsers => prefix(["calc"], {
  props: ["*"],
  feature: "calc",
  browsers
})); // Background options

let prefixBackgroundOptions = _backgroundImgOpts;
f(prefixBackgroundOptions, browsers => prefix(["background-origin", "background-size"], {
  feature: "background-img-opts",
  browsers
})); // background-clip: text

let prefixBackgroundClipText = _backgroundClipText;
f(prefixBackgroundClipText, browsers => prefix(["background-clip"], {
  feature: "background-clip-text",
  browsers
})); // Font feature settings

let prefixFontFeature = _fontFeature;
f(prefixFontFeature, browsers => prefix(["font-feature-settings", "font-variant-ligatures", "font-language-override"], {
  feature: "font-feature",
  browsers
})); // CSS font-kerning property

let prefixFontKerning = _fontKerning;
f(prefixFontKerning, browsers => prefix(["font-kerning"], {
  feature: "font-kerning",
  browsers
})); // Border image

let prefixBorderImage = _borderImage;
f(prefixBorderImage, browsers => prefix(["border-image"], {
  feature: "border-image",
  browsers
})); // Selection selector

let prefixSelection = _cssSelection;
f(prefixSelection, browsers => prefix(["::selection"], {
  selector: true,
  feature: "css-selection",
  browsers
})); // Placeholder selector

let prefixPlaceholder = _cssPlaceholder;
f(prefixPlaceholder, browsers => {
  prefix(["::placeholder"], {
    selector: true,
    feature: "css-placeholder",
    browsers: browsers.concat(["ie 10 old", "ie 11 old", "firefox 18 old"])
  });
}); // Placeholder-shown selector

let prefixPlaceholderShown = _cssPlaceholderShown;
f(prefixPlaceholderShown, browsers => {
  prefix([":placeholder-shown"], {
    selector: true,
    feature: "css-placeholder-shown",
    browsers
  });
}); // Hyphenation

let prefixHyphens = _cssHyphens;
f(prefixHyphens, browsers => prefix(["hyphens"], {
  feature: "css-hyphens",
  browsers
})); // Fullscreen selector

let prefixFullscreen = _fullscreen;
f(prefixFullscreen, browsers => prefix([":fullscreen"], {
  selector: true,
  feature: "fullscreen",
  browsers
}));
f(prefixFullscreen, {
  match: /x(\s#2|$)/
}, browsers => prefix(["::backdrop"], {
  selector: true,
  feature: "fullscreen",
  browsers
})); // File selector button

let prefixFileSelectorButton = _cssFileSelectorButton;
f(prefixFileSelectorButton, browsers => prefix(["::file-selector-button"], {
  selector: true,
  feature: "file-selector-button",
  browsers
})); // :autofill

let prefixAutofill = _cssAutofill;
f(prefixAutofill, browsers => prefix([":autofill"], {
  selector: true,
  feature: "css-autofill",
  browsers
})); // Tab size

let prefixTabsize = _css3Tabsize;
f(prefixTabsize, browsers => prefix(["tab-size"], {
  feature: "css3-tabsize",
  browsers
})); // Intrinsic & extrinsic sizing

let prefixIntrinsic = _intrinsicWidth;
let sizeProps = ["width", "min-width", "max-width", "height", "min-height", "max-height", "inline-size", "min-inline-size", "max-inline-size", "block-size", "min-block-size", "max-block-size", "grid", "grid-template", "grid-template-rows", "grid-template-columns", "grid-auto-columns", "grid-auto-rows"];
f(prefixIntrinsic, browsers => prefix(["max-content", "min-content"], {
  props: sizeProps,
  feature: "intrinsic-width",
  browsers
}));
f(prefixIntrinsic, {
  match: /x|\s#4/
}, browsers => prefix(["fill", "fill-available"], {
  props: sizeProps,
  feature: "intrinsic-width",
  browsers
}));
f(prefixIntrinsic, {
  match: /x|\s#5/
}, browsers => prefix(["fit-content"], {
  props: sizeProps,
  feature: "intrinsic-width",
  browsers
})); // Stretch value

let prefixStretch = _cssWidthStretch;
f(prefixStretch, browsers => prefix(["stretch"], {
  props: sizeProps,
  feature: "css-width-stretch",
  browsers
})); // Zoom cursors

let prefixCursorsNewer = _css3CursorsNewer;
f(prefixCursorsNewer, browsers => prefix(["zoom-in", "zoom-out"], {
  props: ["cursor"],
  feature: "css3-cursors-newer",
  browsers
})); // Grab cursors

let prefixCursorsGrab = _css3CursorsGrab;
f(prefixCursorsGrab, browsers => prefix(["grab", "grabbing"], {
  props: ["cursor"],
  feature: "css3-cursors-grab",
  browsers
})); // Sticky position

let prefixSticky = _cssSticky;
f(prefixSticky, browsers => prefix(["sticky"], {
  props: ["position"],
  feature: "css-sticky",
  browsers
})); // Pointer Events

let prefixPointer = _pointer;
f(prefixPointer, browsers => prefix(["touch-action"], {
  feature: "pointer",
  browsers
})); // Text decoration

let prefixDecoration = _textDecoration;
f(prefixDecoration, {
  match: /x.*#[235]/
}, browsers => prefix(["text-decoration-skip", "text-decoration-skip-ink"], {
  feature: "text-decoration",
  browsers
}));
let prefixDecorationShorthand = _mdnTextDecorationShorthand;
f(prefixDecorationShorthand, browsers => prefix(["text-decoration"], {
  feature: "text-decoration",
  browsers
}));
let prefixDecorationColor = _mdnTextDecorationColor;
f(prefixDecorationColor, browsers => prefix(["text-decoration-color"], {
  feature: "text-decoration",
  browsers
}));
let prefixDecorationLine = _mdnTextDecorationLine;
f(prefixDecorationLine, browsers => prefix(["text-decoration-line"], {
  feature: "text-decoration",
  browsers
}));
let prefixDecorationStyle = _mdnTextDecorationStyle;
f(prefixDecorationStyle, browsers => prefix(["text-decoration-style"], {
  feature: "text-decoration",
  browsers
})); // Text Size Adjust

let prefixTextSizeAdjust = _textSizeAdjust;
f(prefixTextSizeAdjust, browsers => prefix(["text-size-adjust"], {
  feature: "text-size-adjust",
  browsers
})); // CSS Masks

let prefixCssMasks = _cssMasks;
f(prefixCssMasks, browsers => {
  prefix(["mask-clip", "mask-composite", "mask-image", "mask-origin", "mask-repeat", "mask-border-repeat", "mask-border-source"], {
    feature: "css-masks",
    browsers
  });
  prefix(["mask", "mask-position", "mask-size", "mask-border", "mask-border-outset", "mask-border-width", "mask-border-slice"], {
    feature: "css-masks",
    browsers
  });
}); // CSS clip-path property

let prefixClipPath = _cssClipPath;
f(prefixClipPath, browsers => prefix(["clip-path"], {
  feature: "css-clip-path",
  browsers
})); // Fragmented Borders and Backgrounds

let prefixBoxdecoration = _cssBoxdecorationbreak;
f(prefixBoxdecoration, browsers => prefix(["box-decoration-break"], {
  feature: "css-boxdecorationbreak",
  browsers
})); // CSS3 object-fit/object-position

let prefixObjectFit = _objectFit;
f(prefixObjectFit, browsers => prefix(["object-fit", "object-position"], {
  feature: "object-fit",
  browsers
})); // CSS Shapes

let prefixShapes = _cssShapes;
f(prefixShapes, browsers => prefix(["shape-margin", "shape-outside", "shape-image-threshold"], {
  feature: "css-shapes",
  browsers
})); // CSS3 text-overflow

let prefixTextOverflow = _textOverflow;
f(prefixTextOverflow, browsers => prefix(["text-overflow"], {
  feature: "text-overflow",
  browsers
})); // Viewport at-rule

let prefixDeviceadaptation = _cssDeviceadaptation;
f(prefixDeviceadaptation, browsers => prefix(["@viewport"], {
  feature: "css-deviceadaptation",
  browsers
})); // Resolution Media Queries

let prefixResolut = _cssMediaResolution;
f(prefixResolut, {
  match: /( x($| )|a #2)/
}, browsers => prefix(["@resolution"], {
  feature: "css-media-resolution",
  browsers
})); // CSS text-align-last

let prefixTextAlignLast = _cssTextAlignLast;
f(prefixTextAlignLast, browsers => prefix(["text-align-last"], {
  feature: "css-text-align-last",
  browsers
})); // Crisp Edges Image Rendering Algorithm

let prefixCrispedges = _cssCrispEdges;
f(prefixCrispedges, {
  match: /y x|a x #1/
}, browsers => prefix(["pixelated"], {
  props: ["image-rendering"],
  feature: "css-crisp-edges",
  browsers
}));
f(prefixCrispedges, {
  match: /a x #2/
}, browsers => prefix(["image-rendering"], {
  feature: "css-crisp-edges",
  browsers
})); // Logical Properties

let prefixLogicalProps = _cssLogicalProps;
f(prefixLogicalProps, browsers => prefix(["border-inline-start", "border-inline-end", "margin-inline-start", "margin-inline-end", "padding-inline-start", "padding-inline-end"], {
  feature: "css-logical-props",
  browsers
}));
f(prefixLogicalProps, {
  match: /x\s#2/
}, browsers => prefix(["border-block-start", "border-block-end", "margin-block-start", "margin-block-end", "padding-block-start", "padding-block-end"], {
  feature: "css-logical-props",
  browsers
})); // CSS appearance

let prefixAppearance = _cssAppearance;
f(prefixAppearance, {
  match: /#2|x/
}, browsers => prefix(["appearance"], {
  feature: "css-appearance",
  browsers
})); // CSS Scroll snap points

let prefixSnappoints = _cssSnappoints;
f(prefixSnappoints, browsers => prefix(["scroll-snap-type", "scroll-snap-coordinate", "scroll-snap-destination", "scroll-snap-points-x", "scroll-snap-points-y"], {
  feature: "css-snappoints",
  browsers
})); // CSS Regions

let prefixRegions = _cssRegions;
f(prefixRegions, browsers => prefix(["flow-into", "flow-from", "region-fragment"], {
  feature: "css-regions",
  browsers
})); // CSS image-set

let prefixImageSet = _cssImageSet;
f(prefixImageSet, browsers => prefix(["image-set"], {
  props: ["background", "background-image", "border-image", "cursor", "mask", "mask-image", "list-style", "list-style-image", "content"],
  feature: "css-image-set",
  browsers
})); // Writing Mode

let prefixWritingMode = _cssWritingMode;
f(prefixWritingMode, {
  match: /a|x/
}, browsers => prefix(["writing-mode"], {
  feature: "css-writing-mode",
  browsers
})); // Cross-Fade Function

let prefixCrossFade = _cssCrossFade;
f(prefixCrossFade, browsers => prefix(["cross-fade"], {
  props: ["background", "background-image", "border-image", "mask", "list-style", "list-style-image", "content", "mask-image"],
  feature: "css-cross-fade",
  browsers
})); // Read Only selector

let prefixReadOnly = _cssReadOnlyWrite;
f(prefixReadOnly, browsers => prefix([":read-only", ":read-write"], {
  selector: true,
  feature: "css-read-only-write",
  browsers
})); // Text Emphasize

let prefixTextEmphasis = _textEmphasis;
f(prefixTextEmphasis, browsers => prefix(["text-emphasis", "text-emphasis-position", "text-emphasis-style", "text-emphasis-color"], {
  feature: "text-emphasis",
  browsers
})); // CSS Grid Layout

let prefixGrid = _cssGrid;
f(prefixGrid, browsers => {
  prefix(["display-grid", "inline-grid"], {
    props: ["display"],
    feature: "css-grid",
    browsers
  });
  prefix(["grid-template-columns", "grid-template-rows", "grid-row-start", "grid-column-start", "grid-row-end", "grid-column-end", "grid-row", "grid-column", "grid-area", "grid-template", "grid-template-areas", "place-self"], {
    feature: "css-grid",
    browsers
  });
});
f(prefixGrid, {
  match: /a x/
}, browsers => prefix(["grid-column-align", "grid-row-align"], {
  feature: "css-grid",
  browsers
})); // CSS text-spacing

let prefixTextSpacing = _cssTextSpacing;
f(prefixTextSpacing, browsers => prefix(["text-spacing"], {
  feature: "css-text-spacing",
  browsers
})); // :any-link selector

let prefixAnyLink = _cssAnyLink;
f(prefixAnyLink, browsers => prefix([":any-link"], {
  selector: true,
  feature: "css-any-link",
  browsers
})); // unicode-bidi

let bidiIsolate = _mdnCssUnicodeBidiIsolate;
f(bidiIsolate, browsers => prefix(["isolate"], {
  props: ["unicode-bidi"],
  feature: "css-unicode-bidi",
  browsers
}));
let bidiPlaintext = _mdnCssUnicodeBidiPlaintext;
f(bidiPlaintext, browsers => prefix(["plaintext"], {
  props: ["unicode-bidi"],
  feature: "css-unicode-bidi",
  browsers
}));
let bidiOverride = _mdnCssUnicodeBidiIsolateOverride;
f(bidiOverride, {
  match: /y x/
}, browsers => prefix(["isolate-override"], {
  props: ["unicode-bidi"],
  feature: "css-unicode-bidi",
  browsers
})); // overscroll-behavior selector

let prefixOverscroll = _cssOverscrollBehavior;
f(prefixOverscroll, {
  match: /a #1/
}, browsers => prefix(["overscroll-behavior"], {
  feature: "css-overscroll-behavior",
  browsers
})); // text-orientation

let prefixTextOrientation = _cssTextOrientation;
f(prefixTextOrientation, browsers => prefix(["text-orientation"], {
  feature: "css-text-orientation",
  browsers
})); // print-color-adjust

let prefixPrintAdjust = _cssPrintColorAdjust;
f(prefixPrintAdjust, browsers => prefix(["print-color-adjust", "color-adjust"], {
  feature: "css-print-color-adjust",
  browsers
}));
export default exports;